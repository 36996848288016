.container.simulcast {
  .main-grid {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;

      .lot-card { order: 1; min-height: 40vh; }
      .history-card { order: 2; min-height: 40vh; }
      .bid-card { order: 3; min-height: 40vh; }
      .notification-card { order: 4; min-height: 40vh; }
    }

    .card {
      margin-left: 5px;
      margin-right: 5px;
    }

    .auction-activity {
      flex: 2 0 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      & > * {
        flex: 1 0 40%;
      }

      @media (max-width: 767px) {
        flex: 0 1 auto;
        flex-direction: column;
      }
    }
    .lot-list {
      flex: 1 0 0;

      @media (min-width: 768px) {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
      }
    }

    .bid-card {
      display: flex;
      flex-direction: column;
      padding: 15px;

      #bidInfo {
        .winningBid {
          font-size: 20px;
          margin-top:3px;
          margin-right: 7px;
        }
      }
    }
  }

  #upcomingList {
    overflow-y: auto;
    flex: 1 1 auto;

    img {
      max-height: 200px;
      margin: 0 auto;
    }
    .description {
      max-height: 40px;
      overflow: hidden;
    }
    .row {
      -webkit-transition: opacity.25s;
      -moz-transition: opacity.25s;
      -ms-transition: opacity.25s;
      -o-transition: opacity.25s;
      transition: opacity.25s;
      max-height: 300px;
      padding: 5px;
      

      &.active {
        background: #d3ebff;
      }

      & + .row {
        border-top: 1px solid #ccc;
      }
    }
    h3 {
      margin-top: 0;
      font-size: 20px;
    }
    h2 {
      margin-top: 0;
    }
    .description, .description p {
      font-size: 12px;
    }
  }

  #bidStatus {
    margin-bottom: 10px;
    text-align: center;

    span, a{
      border:1px solid #C8C8C8;
      border-radius: 5px;
      padding: 6px 10px;
      margin: 3px;
      color: #000;
      text-decoration: none;
      background: #FFF;
    }
    a{
      cursor: pointer;
    }
    .once.active {
      background-color: #42A316;
      color: #FFF;
      border-color: #42A316;
    }
    .selling.active {
      background-color: #FFBD32;
      color: #FFF;
      border-color: #FFBD32;
    }
    .sold.active {
      background-color: #d20d0d;
      color: #FFF;
      border-color: #d20d0d;
    }
  }

  #askingIncrements {
    margin-top: 10px;

    h4.bar {
      margin: 5px -15px 5px;

      a {
        color: #FFF;
        cursor: pointer;
      }
    }

    .increments a{
      margin: 2px;
    }
  }

  #approval {
    margin-top: 10px;

    > h4.bar {
      margin: 5px -15px 5px;

      .checkbox {
        font-size: 12px;
        line-height: 18px;
        margin-top: 0px;
      }
    }
  }

  #pendingApproval {
    max-height: 75px;
    overflow-y: scroll;
    width:100%;
    overflow-x: hidden;

    > div {
      font-size:12px;

      a {
        margin-bottom: 2px;
      }
    }
  }

  .notification-card {
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    #auctionNotifications {
      flex: 1 1 auto;
    }
  }

  #auctionNotifications {
    position: relative;
    margin-bottom: 2px;
    overflow-y: scroll;
    margin: 0 0 0 10px;
  }

  .history-card {
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    
    #bidHistory {
      flex: 1 1 auto;
    }
  }
  #bidHistory {
    position: relative;
    overflow-y: scroll;
    margin: 0 0 0 10px;
  }

  .setActive {
    margin-left: 5px;
    cursor:pointer;
  }

  #livePics {
    position: relative;

    .media-main {
      width: calc(100% - 20px);
      padding-top: 10px;
      margin: 0 auto;
    }
  }

  #liveDetails {
    position: relative;

    .description {
      max-height: 125px;
      overflow: hidden;
    }
    .title {
      font-size: 28px;
    }
  }

  .bidBox {
    margin-top: 15px;
  }
  .video-box + .bidBox {
    margin-top: 0;
  }

  @media (max-width: 400px) {
    .video-box {
      display: none
    }
  }
  /*
  

  .bidBox {
    padding-bottom: 20px;
    width: 96%;
  }

  .container-wide {
    width: 100%;
    max-width: 1400px;
  }

  
*/
}
